var _moneyRange;
var pageTop;
hthink.source = 'landingpage';
(function (document, window,$) {
    $(function(){
        var loadEnd = setInterval(function(){
          if(hthink.kvLoad){
            adjust();
            clearInterval(loadEnd);
          }
        },10)
        init();
        $(window).resize(adjust)
    });
    function init(){
        var _rangeW = $('.budget').width();
        _moneyRange = $('#budgetMoney').jRange({
            from: 0,
            to: 100,
            width:'100%',
            format: '%s',
            showLabels: true,
            showScale: false,
            onstatechange: function(e) {
                var num = e.split(','),
                    min = num[0],
                    max = num[1];
                if(max == min){
                    $('.pointer-label').html('￥'+min+'W+');
                }else{
                    $('.pointer-label').html('￥'+min+'-'+max+'W+');
                }
                
            }
        });
        hthink.isScroll.init('.scrollAni',mapAni);
        _moneyRange.jRange('setValue','5,15');
        $('.section-2 .list li').on('click',function (e) {
            $(this).addClass('active').siblings().removeClass('active');
        });
        $('.section-3 .item').on('click',function (e) {
            $(this).addClass('active').siblings().removeClass('active');
        });

        $('.section-10 form label input').on('input change',function (e) { 
            var val = $(this).val().length;
            if(val > 0){
                $(this).parent().addClass('is-filled'); 
            }else{
                $(this).parent().removeClass('is-filled'); 
            }
        });
        $('.section-10 .serve .list li').on('click',function (e) { 
            var min = $(this).data('min'),
                max = $(this).data('max');
            $(this).addClass('active').siblings().removeClass('active');
            if(!min){
                _moneyRange.jRange('setValue',max+','+max)
            }else{
                _moneyRange.jRange('setValue',min+','+max)
            }
        });
        $('.lanpg nav li').on('click',function(){
          var idx = $(this).index();
          hthink.pageScroll(pageTop[idx]);
        });
        $('.nav-drop .nav-menu li').on('click',function(){
          var idx = $(this).index();
          hthink.pageScroll(pageTop[idx]);
          $('header').removeClass('m-navhover');
          $('.m-menu').removeClass('active');
          $('.nav-drop').transition({
              y: '-100%',
              opacity:0
          }, 600);
        });
        $('.lanpg .consult').on('click',function(){
          var len = pageTop.length;
          hthink.pageScroll(pageTop[len-1]);
        });
        $('.popnav li').eq(1).on('click',function(){
          var len = pageTop.length;
          hthink.pageScroll(pageTop[len-1]);
        });
        $('.openqq').on('click',function(){
          if(hthink.width <= hthink.viewMode.minTablet){
            window.open('mqqwpa://im/chat?chat_type=wpa&uin=810866373&version=1&src_type=web&web_src=oicqzone.com','_blank');
          }else{
            window.open('http://wpa.qq.com/msgrd?v=3&uin=810866373&site=qq&menu=yes&from=message&isappinstalled=0','_blank');
          }
        });
        brandNeed();
    }
    function adjust() {
      pageTop  = [];
      setTimeout(function(){
        var headerH = $('header').height();
        $('.lanpg .navscroll').each(function(){
          var top = $(this).offset().top - headerH;
          pageTop.push(top);
        });
      },1000)
    }
    function brandNeed(){
        $('#brandNeed').submit(function(e){
            e.preventDefault();
            var that = $(this);
            var username = $('#username').val(),
                mobile = $('#mobile').val(),
                qq = $('#qqNumber').val(),
                service = $('#brandNeed .list li.active').text(),
                budget = $('#budgetMoney').val();
            if(username == ''){
              swal({
                icon:'warning',
                title:'提示信息',
                text:'请填写您的名字',
                button:false
              });
              return
            }
            if(mobile == ''){
              swal({
                icon:'warning',
                title:'提示信息',
                text:'请填写联系方式',
                button:false
              });
              return
            }
            if(!(/^1[345678]\d{9}$/.test(mobile))){
              swal({
                icon:'warning',
                title:'提示信息',
                text:'请正确填写联系方式',
                button:false
              });
              return
            }
            if(qq == ''){
              swal({
                icon:'warning',
                title:'提示信息',
                text:'请填写联系QQ',
                button:false
              });
              return
            }
            if(qq.length < 5){
              swal({
                icon:'warning',
                title:'提示信息',
                text:'请正确填写联系QQ',
                button:false
              });
              return
            }
            if (that.hasClass('disabled')) return;
            that.addClass('disabled');
            $.post("/index/index/budgetPost.php",{
              username:username,
              mobile:mobile,
              service:service,
              budget:budget,
              source:hthink.source
            },null,"json").done(function(res){
              that.removeClass('disabled');
              if(res.status == 'ok'){
                swal({
                  icon:'success',
                  title:'提交成功',
                  text:'我们的品牌官网定制专家将会尽快联系您！',
                  button:false
                });
              }else{
                hthink.ajaxError(res.errDesc);
              }
            }).fail(function(){
              that.removeClass('disabled');
              hthink.ajaxError('可能因为您网络的原因，请重新提交您的信息！');
            });
          });
    }
    function mapAni(){
        $(".num-1").numberRock({
            speed:20,
            count:700
        });
        $(".num-2").numberRock({
            speed:20,
            count:800
        });
        $(".num-3").numberRock({
            speed:20,
            count:700
        });
        $(".num-4").numberRock({
            speed:20,
            count:700
        });
    }
  })(document,window,jQuery);